import React, { useState } from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const talentTypes = ['Strength', 'Intelligence', 'Leadership', 'Charisma'];
const baseTalentStars = [
    { name: '1☆', value: '1' },
    { name: '2☆', value: '2' },
    { name: '3☆', value: '3' },
    { name: '4☆', value: '4' },
    { name: '5☆', value: '5' },
    { name: '6☆', value: '6' }
];

const TalentTodo = ({ formValue, setFormValue, knightInfo }) => {
    const [talentStars, setTalentStars] = useState(baseTalentStars);
    const handleInput = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleUpgrade = (e) => {
        setUpgrade(e.currentTarget.value, formValue.upgradeMethod);
    }
    const handleAttInput = (e) => {
        let talentOptions;
        if (e.target.value == "Strength") {
            talentOptions = knightInfo.strengthTalentStars.split(",");
        }
        else if (e.target.value == "Intelligence")
            talentOptions = knightInfo.intelTalentStars.split(",");
        else if (e.target.value == "Leadership")
            talentOptions = knightInfo.leadershipTalentStars.split(",");
        else if (e.target.value == "Charisma")
            talentOptions = knightInfo.charismaTalentStars.split(",");
        setTalentStars(baseTalentStars.filter(star => talentOptions.includes(star.value)));

        if (talentStars.length === 1)
            setFormValue({ ...formValue, ['targetOption']: talentStars[0].value, [e.target.name]: e.target.value });
        else
            setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }

    const handleMethodChange = (e) => {
        const oldMethod = formValue.upgradeMethod;
        let upgradeValue = 0;
        if (oldMethod == "by")
            upgradeValue = formValue.upgradeValue;
        else if (oldMethod == "to")
            upgradeValue = formValue.upgradeTarget;
        else if (oldMethod == "using")
            upgradeValue = formValue.upgradeCost;

        setUpgrade(upgradeValue, e.target.value);
    }

    const setUpgrade = (newValue, method) => {
        if (method == 'by') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeValue']: newValue,
                ['upgradeResource']: "",
                ['upgradeTarget']: null,
                ['upgradeCost']: null
            });
        }
        else if (method == 'to') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeTarget']: newValue,
                ['upgradeResource']: "",
                ['upgradeValue']: null,
                ['upgradeCost']: null
            });
        }
        else if (method == 'using') {
            setFormValue({
                ...formValue,
                ['upgradeMethod']: method,
                ['upgradeCost']: newValue,
                ['upgradeResource']: formValue.upgradeResource,
                ['upgradeValue']: null,
                ['upgradeTarget']: null
            });
        }
    }

    const currentValue = formValue.upgradeMethod == "by" ? formValue.upgradeValue : formValue.upgradeMethod == "to" ? formValue.upgradeTarget : formValue.upgradeCost;

    return (
        <>
            <Form.Label className="mt-3">Which one?</Form.Label>
            <Row>
                <Col>
                    <Form.Select
                        defaultValue={formValue.targetAtt}
                        name="targetAtt"
                        onChange={handleAttInput}>
                        <option hidden>Select type</option>
                        {talentTypes.map((type, idx) => (
                            <option
                                key={idx}
                                id={`option-${idx}`}
                                value={type}>
                                {type}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select
                        name="targetOption"
                        onChange={handleInput}
                        disabled={!(formValue.targetAtt)}
                        value={formValue.targetOption}>
                        <option hidden>Select star</option>
                        {talentStars.map((star, idx) => (
                            <option
                                key={idx}
                                id={`option-${idx}`}
                                value={star.value}>
                                {star.name}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
            {formValue.targetAtt && formValue.targetOption &&
                <>
                    <Form.Label className="mt-3">How?</Form.Label>
                    <Form.Check
                        defaultChecked={formValue.upgradeMethod == "by"}
                        onClick={handleMethodChange}
                        type="radio"
                        name="upgradeMethod"
                        value="by"
                        id="talent-method-by"
                        label="By X levels" />
                    <Form.Check
                        defaultChecked={formValue.upgradeMethod == "to"}
                        onClick={handleMethodChange}
                        type="radio"
                        name="upgradeMethod"
                        value="to"
                        label="To level X"
                        id="talent-method-to" />
                    <Form.Check
                        defaultChecked={formValue.upgradeMethod == "using"}
                        onClick={handleMethodChange}
                        type="radio"
                        name="upgradeMethod"
                        value="using"
                        label="Using X resources"
                        id="talent-method-using" />
                    <InputGroup className="mt-2">
                        <InputGroup.Text>X</InputGroup.Text>
                        <Form.Control
                            id="talentMethodValue"
                            type="number"
                            onChange={handleUpgrade}
                            value={currentValue}
                            min="0" />
                        {formValue.upgradeMethod == "using" &&
                            <Form.Select
                                onChange={handleInput}
                                name="upgradeResource"
                                defaultValue={formValue.upgradeResource}>
                                <option hidden>Select resource</option>
                                <option
                                    key={0}
                                    id={`option-${0}`}
                                    value="Edicts">
                                    Edicts
                                </option>
                                <option
                                    key={1}
                                    id={`option-${1}`}
                                    value="EXP">
                                    EXP
                                </option>
                            </Form.Select>
                        }
                    </InputGroup>
                </>
            }
        </>
    );
};

TalentTodo.propTypes = {
    formValue: PropTypes.object,
    setFormValue: PropTypes.func,
    knightInfo: PropTypes.object
}

export default TalentTodo;